<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Details</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7"
        style="height: 78vh"
      >
        <Warehouse
          v-if="typeOfComponent === 'warehouse'"
          :detail-data="dataToShow"
        />
        <ZoneGroup
          v-if="typeOfComponent === 'zone_group'"
          :detail-data="dataToShow"
        />
        <Zone v-if="typeOfComponent === 'zone'" :detail-data="dataToShow" />
        <Location
          v-if="typeOfComponent === 'location'"
          :detail-data="dataToShow"
        />
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import Warehouse from "@/own/components/warehouseManagement/warehouses/Show/Warehouse.vue";
import ZoneGroup from "@/own/components/warehouseManagement/warehouses/Show/ZoneGroup.vue";
import Zone from "@/own/components/warehouseManagement/warehouses/Show/Zone.vue";
import Location from "@/own/components/warehouseManagement/warehouses/Show/Location.vue";
export default {
  name: "ShowDetail.vue",
  props: {
    dataToShow: {
      type: Object,
      required: true,
    },
    typeOfComponent: {
      type: String,
      required: true,
    },
  },
  components: {
    Warehouse,
    ZoneGroup,
    Zone,
    Location,
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>
