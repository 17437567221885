<template>
  <div>
    <div class="">
      <div class="d-flex pt-4">
        <span>name:</span>
        <h6 class="pl-2">{{ detailData.name }}</h6>
      </div>
      <div class="d-flex">
        <span>Description:</span>
        <h6 class="pl-2">{{ detailData.description }}</h6>
      </div>
      <div class="d-flex">
        <span>Active:</span>
        <h6 class="pl-2">{{ detailData.is_active }}</h6>
      </div>
      <div class="d-flex">
        <span>Locked:</span>
        <h6 class="pl-2">{{ detailData.is_locked }}</h6>
      </div>
      <div class="d-flex">
        <span>Replenishment control:</span>
        <h6 class="pl-2">{{ detailData.is_replenishment_control }}</h6>
      </div>
      <div class="d-flex">
        <span>Notes:</span>
        <h6 class="pl-2">{{ detailData.notes }}</h6>
      </div>
      <div>
        <h4 class="pt-4">Warehouse</h4>
        <div>
          <div class="d-flex">
            <span>name:</span>
            <h6 class="pl-2">{{ detailData.warehouse.warehouse_name }}</h6>
          </div>
          <div class="d-flex">
            <span>Description:</span>
            <h6 class="pl-2">{{ detailData.warehouse.description }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZoneGroup.vue",
  props: {
    detailData: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    // detailData: {
    //   id: 1,
    //   warehouse: {
    //     id: 7,
    //     warehouse_name: "New Zone Group",
    //     description: "Test description",
    //   },
    //   name: "New Zone Group",
    //   description: "Test description",
    //   is_active: true,
    //   is_locked: false,
    //   is_replenishment_control: false,
    //   notes: null,
    // },
  }),
};
</script>
