import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import {
  GET_WAREHOUSES,
  GET_ZONEGROUPS,
  GET_ZONES,
  GET_LOCATIONS,
} from "@/core/services/store/warehouses.module";

export default {
  data: () => ({
    serverData: null,
  }),
  methods: {
    loadDataFromServer() {
      let sendData = {
        id: this.id,
        type: this.editType,
      };
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/warehouse_management/warehouses/edit", sendData)
        .then(({ data }) => {
          this.formData = data.value;
          this.serverData = data;
          this.resetCreateForm();
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    toggleModal() {
      if (!this.dialog) this.loadDataFromServer();
      this.dialog = !this.dialog;
    },
    sentToApi(sendData) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/warehouse_management/warehouses/update", sendData)
        .then(() => {
          swalEdited();
          this.toggleModal();
          if (this.editType === "warehouse")
            this.$store.dispatch(GET_WAREHOUSES).finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
          else if (this.editType === "zone_group") {
            let payload = {
              warehouse_id:
                this.$store.getters.getWAREHOUSESSelectedWarehouseId,
              type: "zone_group",
            };
            this.$store.dispatch(GET_ZONEGROUPS, payload).finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
          } else if (this.editType === "zone") {
            let payload = {
              warehouse_id:
                this.$store.getters.getWAREHOUSESSelectedWarehouseId,
              zone_group_id:
                this.$store.getters.getWAREHOUSESSelectedZoneGroupId,
              type: "zone",
            };
            this.$store.dispatch(GET_ZONES, payload).finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
          } else {
            let payload = {
              warehouse_id:
                this.$store.getters.getWAREHOUSESSelectedWarehouseId,
              zone_group_id:
                this.$store.getters.getWAREHOUSESSelectedZoneGroupId,
              zone_id: this.$store.getters.getWAREHOUSESSelectedZoneId,
              type: "location",
            };
            this.$store.dispatch(GET_LOCATIONS, payload).finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
          }
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
};
