<template>
  <div>
    <div class="">
      <div>
        <div class="d-flex">
          <span>name:</span>
          <h6 class="pl-2">{{ detailData.name }}</h6>
        </div>
        <div class="d-flex">
          <span>Location type:</span>
          <h6 class="pl-2">{{ detailData.location_type.title }}</h6>
        </div>
        <div class="d-flex">
          <span>Location attribute:</span>
          <h6 class="pl-2">{{ detailData.location_attribute.title }}</h6>
        </div>
        <div class="d-flex">
          <span>Location category:</span>
          <h6 class="pl-2">{{ detailData.location_category.title }}</h6>
        </div>
        <div class="d-flex">
          <span>Putaway sequence:</span>
          <h6 class="pl-2">{{ detailData.putaway_sequence }}</h6>
        </div>
        <div class="d-flex">
          <span>Pick sequence:</span>
          <h6 class="pl-2">{{ detailData.pick_sequence }}</h6>
        </div>
        <div class="d-flex">
          <span>Allow mix sku:</span>
          <h6 class="pl-2">{{ detailData.is_allow_mix_sku }}</h6>
        </div>
        <div class="d-flex">
          <span>Allow mix lot:</span>
          <h6 class="pl-2">{{ detailData.is_allow_mix_lot }}</h6>
        </div>
        <div class="d-flex">
          <span>Capacity length:</span>
          <h6 class="pl-2">{{ detailData.capacity_length }}</h6>
        </div>
        <div class="d-flex">
          <span>Capacity width:</span>
          <h6 class="pl-2">{{ detailData.capacity_width }}</h6>
        </div>
        <div class="d-flex">
          <span>Capacity height:</span>
          <h6 class="pl-2">{{ detailData.capacity_height }}</h6>
        </div>
        <div class="d-flex">
          <span>Weight capacity:</span>
          <h6 class="pl-2">{{ detailData.weight_capacity }}</h6>
        </div>
        <div class="d-flex">
          <span>Cubic capacity:</span>
          <h6 class="pl-2">{{ detailData.cubic_capacity }}</h6>
        </div>
        <div class="d-flex">
          <span>Sku count:</span>
          <h6 class="pl-2">{{ detailData.sku_count }}</h6>
        </div>
        <div class="d-flex">
          <span>Each count:</span>
          <h6 class="pl-2">{{ detailData.each_count }}</h6>
        </div>
        <div class="d-flex">
          <span>Case count:</span>
          <h6 class="pl-2">{{ detailData.case_count }}</h6>
        </div>
        <div class="d-flex">
          <span>Pallet count:</span>
          <h6 class="pl-2">{{ detailData.pallet_count }}</h6>
        </div>
        <div class="d-flex">
          <span>Notes:</span>
          <h6 class="pl-2">{{ detailData.notes }}</h6>
        </div>
        <div class="d-flex">
          <span>Active:</span>
          <h6 class="pl-2">{{ detailData.is_active }}</h6>
        </div>
        <div class="d-flex">
          <span>Locked:</span>
          <h6 class="pl-2">{{ detailData.is_locked }}</h6>
        </div>
        <div class="d-flex">
          <span>Replenishment control:</span>
          <h6 class="pl-2">{{ detailData.is_replenishment_control }}</h6>
        </div>
      </div>

      <div>
        <h4 class="pt-4">Warehouse</h4>
        <div>
          <div class="d-flex">
            <span>name:</span>
            <h6 class="pl-2">{{ detailData.warehouse_id.warehouse_name }}</h6>
          </div>
          <div class="d-flex">
            <span>Description:</span>
            <h6 class="pl-2">{{ detailData.warehouse_id.description }}</h6>
          </div>
        </div>
      </div>
      <div>
        <h4 class="pt-4">Zone group</h4>
        <div>
          <div class="d-flex">
            <span>name:</span>
            <h6 class="pl-2">{{ detailData.zone_group.name }}</h6>
          </div>
        </div>
      </div>
      <div>
        <h4 class="pt-4">Zone</h4>
        <div>
          <div class="d-flex">
            <span>name:</span>
            <h6 class="pl-2">{{ detailData.zone.name }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Location",
  props: {
    detailData: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    // detailData: {
    //   id: 1,
    //   warehouse_id: {
    //     id: 10,
    //     warehouse_name: "New Location",
    //     description: null,
    //   },
    //   zone_group: {
    //     id: 1,
    //     name: "New Zone Group",
    //   },
    //   zone: {
    //     id: 3,
    //     name: "Test Zone",
    //   },
    //   location_type: {
    //     id: 1,
    //     title: "Staging",
    //   },
    //   location_attribute: {
    //     id: 1,
    //     title: "Normal",
    //   },
    //   location_category: {
    //     id: 1,
    //     title: "Shelves",
    //   },
    //   name: "New Location",
    //   putaway_sequence: 1000,
    //   pick_sequence: 1000,
    //   is_allow_mix_sku: true,
    //   is_allow_mix_lot: true,
    //   capacity_length: 12.34,
    //   capacity_width: 12.34,
    //   capacity_height: 12.34,
    //   weight_capacity: 14.34,
    //   cubic_capacity: 14.34,
    //   sku_count: 15.34,
    //   each_count: 15.34,
    //   case_count: 16.34,
    //   pallet_count: 16.34,
    //   notes: null,
    // },
  }),
};
</script>
