import Pusher from "pusher-js";

const getPusherInstance = () => {
  const APP_KEY = process.env.VUE_APP_PUSHER_APP_KEY;
  const APP_CLUSTER = process.env.VUE_APP_PUSHER_CLUSTER;
  // console.log(APP_CLUSTER, APP_KEY);

  // const APP_KEY = "1982dbb69c1e1e4b475b";
  // const APP_CLUSTER = "ap2";

  if (!APP_KEY || !APP_CLUSTER) {
    throw Error(
      "Pusher APP_KEY or APP_CLUSTER not found in environment variables."
    );
  }

  return new Pusher(APP_KEY, {
    cluster: APP_CLUSTER,
    enabledTransports: ["ws", "wss"],
    forceTLS: true,
  });
};

export default getPusherInstance;
